import React from 'react';
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { PlayerTimeLineOverlay, PlayerInput, PlayerTimer, PlayerControlIcon } from './playertimeline.style';
import ImageLoader from '../pageloaders/ImageLoader';
import { IoIosPlay, IoIosPause } from 'react-icons/io'
import { VscLoading } from 'react-icons/vsc'
import { MdReplay } from 'react-icons/md'

function PlayerTimeLine(
  { playStatus, togglePlayPause, maxVal, currentVal,
    changeTimeLinePos,
    setTimelinePos,
    totalTime,
    currentTime,
    status,
    setStatus,
    restartPlayback,
    iosViewer,
    iosExpand,
    setIosExpand,
    iosExpandHandler,
    liveStreamTimer,
    liveMode,
  }) {
  function chooseIcon(statusCheck) {
    switch (statusCheck) {
      case playStatus.PAUSED:
        return <IoIosPlay />
      case playStatus.BUFFERING:
        return <VscLoading />
      case playStatus.FINISHED:
        return <MdReplay />
      default:
        return <IoIosPause />;
    }
  }
  return (
    <>

      <PlayerTimeLineOverlay iosViewer={iosViewer} className='col-md-12 text-center'>
        <div className="col-8 d-none d-lg-block mb-1" style={{ marginLeft: 60 }}>
          <div className="col-2 me-auto">
            {!iosViewer ? <ImageLoader
              src='https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Logo.png'
              cls='img-fluid'
            /> : <></>}
          </div>
        </div>

        {!liveMode ?
          <div className="col-11 px-1 col-lg-10 mx-auto" id={iosExpand ? "player_bar" : ''}>
            <div className="row">
              <MDBCol className='col-lg-1 col-md-2 m-0 p-0' id="play-pause-">
                <div className="row">
                  <div className="col-6 ps-4">
                    {iosExpand ?
                      <PlayerControlIcon
                        status={status}
                        setStatus={setStatus}
                        playStatus={playStatus}
                        restartPlayback={() => restartPlayback(playStatus, setTimelinePos, setStatus)}
                        chooseIcon={chooseIcon}
                        togglePlayPause={togglePlayPause}
                      /> :
                      <></>
                    }
                  </div>
                  <div className="col-6">
                    {iosViewer ?
                      <div className="" id="left-timeline-">
                        <div className='text-white text-start'>
                          <PlayerTimer className={`small pt-2`}>
                            {iosExpand ? <>
                              {currentTime ? `${currentTime.slice(-5)}` : ""}
                              {/* {totalTime.slice(-5)} */}
                            </>
                              :
                              <>&ensp;</>}
                          </PlayerTimer>
                        </div>
                      </div>
                      : <></>
                    }
                  </div>


                </div>

              </MDBCol>


              <MDBCol className={`col-lg-10 col-md-8 text-center mx-auto`}>
                {iosExpand ?
                  <>
                    <PlayerInput
                      type="range"
                      name="range"
                      max={parseInt(maxVal)}
                      step="1"
                      value={parseInt(currentVal)}
                      className="m-0 p-0"
                      onChange={(e) => changeTimeLinePos(e, setTimelinePos)}
                      id="range" />
                  </> : <></>}
              </MDBCol>





              {/* <MDBCol className={`col-${iosViewer ? 1 : 1} text-white mt-1`}>
                {!iosViewer ?
                  <PlayerTimer className='small pt-2'>{currentTime ? `${currentTime.slice(-5)} / ` : ""}{totalTime.slice(-5)}</PlayerTimer>
                  :
                  <span onClick={() => iosExpandHandler(iosExpand)}>
                    <i className={` ${iosExpand ? 'bi bi-fullscreen' : 'bi bi-fullscreen-exit'} fw-bolder"`}></i>
                  </span>}
              </MDBCol> */}


              <MDBCol className={`col-lg-1 col-md-2 text-white`} id="expander-">
                <div className="row">
                  <div className="col-6 col-md-4 p-md-0">
                    {iosViewer ?
                      <div className="" id="right-timeline-">
                        <MDBCol className='text-white text-end'>
                          <PlayerTimer className={`small pt-2`}>
                            {iosExpand ? <>
                              {/* {currentTime ? `${currentTime.slice(-5)} / ` : ""} */}
                              {totalTime.slice(-5)}
                            </> : <>&ensp;</>}
                          </PlayerTimer>
                        </MDBCol>
                      </div>
                      : <></>
                    }
                  </div>

                  <div className="col-6 pe-4 col-md-6">
                    <p onClick={() => iosExpandHandler(iosExpand, setIosExpand)} className="expand_btn- me-5 mt-1">
                      <i className={` ${iosExpand ? 'bi bi-fullscreen' : 'bi bi-fullscreen-exit'} fw-bolder"`}></i>
                    </p>
                  </div>

                </div>
              </MDBCol>

            </div>

          </div>

          :
          <>
            <MDBRow>
              <MDBCol className='ps-5 col-1 text-end'>
                {!iosExpand ?
                  // <PlayerControlIcon
                  //   status={status}
                  //   setStatus={setStatus}
                  //   playStatus={playStatus}
                  //   restartPlayback={() => restartPlayback(playStatus, setTimelinePos, setStatus)}
                  //   chooseIcon={chooseIcon}
                  //   togglePlayPause={togglePlayPause}
                  // /> 
                  <p onClick={() => iosExpandHandler(iosExpand, setIosExpand)} className="expand_btn- me-5 mt-1">
                    <i className={` ${iosExpand ? 'bi bi-fullscreen' : 'bi bi-fullscreen-exit'} fw-bolder"`}></i>
                  </p>
                  :
                  <>
                    <p onClick={() => iosExpandHandler(iosExpand, setIosExpand)} className="expand_btn- me-5 mt-1">
                      <i className={` ${iosExpand ? 'bi bi-fullscreen' : 'bi bi-fullscreen-exit'} fw-bolder"`}></i>
                    </p>
                  </>
                }
              </MDBCol>
              {/* Live stream player counter */}
              <MDBCol className='ps-3 col-6 col-md-3 mt-1 text-start'>
                <span className="border px-1 rounded">
                  <i className="bi bi-circle-fill live-dot"></i>  LIVE
                </span> &ensp;
                <span className="">
                  {/* {liveStreamTimer.hour !== undefined ? liveStreamTimer.hour : "00"}:{liveStreamTimer.min !== undefined ? liveStreamTimer.min : "00"}:{liveStreamTimer.sec !== undefined ? liveStreamTimer.sec : "00"} */}
                </span>
              </MDBCol>
            </MDBRow>
          </>}
      </PlayerTimeLineOverlay>
    </>
  )
}

export default PlayerTimeLine
