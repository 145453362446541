import React from "react";
import { useLoader, useThree } from "@react-three/fiber"
import * as THREE from "three";
import { CubeTextureLoader } from 'three'
import { Environment } from "@react-three/drei"
import { memo } from 'react';

// eslint-disable-next-line 
const Floor = () => {
    var url = 'https://holotch-viewer-sky-textures.s3.ap-northeast-1.amazonaws.com/RakutenLocker/'
    const base = useLoader(THREE.TextureLoader, url + 'ny.png');
    return (
        <group>
            <mesh receiveShadow rotation-x={-Math.PI / 2} scale={[100, 100, 100]} position={[0,0,0]}>
                <planeBufferGeometry attach='geometry' args={[1, 1, 1000, 1000]} />
                <meshPhysicalMaterial attach='material' map={base}/>
            </mesh>
        </group>
    );
}
const addURLPrefix = x =>
    `https://holotch-viewer-sky-textures.s3.ap-northeast-1.amazonaws.com/RakutenLocker/${x}`;
function SkyBox() {


    const skyboxTextures = [
        "nz.png",
        "pz.png",
        "py.png",
        "ny.png",
        "px.png",
        "nx.png"
    ].map(addURLPrefix);
    const { scene } = useThree();
    const [cubeMapTexture] = useLoader(CubeTextureLoader, [skyboxTextures]);
    // Set the scene background property to the resulting texture.
    scene.background = cubeMapTexture;
    return null;
}

function BaseEnvironment() {
    return (
        <React.Suspense fallback={null}>
            <SkyBox />
            <Environment files={' https://holotch-viewer-sky-textures.s3.ap-northeast-1.amazonaws.com/Beach/spiaggia_di_mondello_1k.hdr'} />
        </React.Suspense>
    )
}

export default memo(BaseEnvironment)
