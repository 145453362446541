import React from 'react';
import { connect } from "react-redux";
import SectionTwo from './sections/SectionTwo';
import SectionOne from './sections/SectionOne';
import SectionThree from './sections/SectionThree';
import DesktopDetailSection from '../../components/detailSection/DesktopDetailSection';
import PlayerTimeLine from '../../components/playertimeline';
import {
    ViewerContainer,
    ViewerDesktopSideBar,
    MobileWrapper,
    CapturedPhotoOverLay,
} from './hologramui.style';
import {
    togglePlayPause,
    restartPlayback,
    setMetadata,
    incrementTimelinePos,
    updateStatus,
    forward2Secs,
    changeTimeLinePos,
    pageLoaderCheck,
} from '../../components/holotchViewer/hologram/hologram.helpers';
import {
    switchViewerMode,
    popMenu,
    popSettingsMobile,
    popDetailsMobile,

} from './hologramUI.redux';
import {
    PlayerGestures,
    PlayerGesturesDesktop,
    // startStreaming,
    // stopRecording,
    startRecording,
    capturePhoto,
    savePhoto,
    handleShare,
    secondsToMinutes,
    iosExpandHandler,
} from '../../components/holotchViewer/hologram/hologramUi.helpers';
// import AFrameRenderer from '../ARTest/AFrameRenderer';
import BaseEnvironment from '../../components/holotchViewer/VRViewer/Environment';
import { Canvas, useThree } from "@react-three/fiber"
import OrbitControls from "./CamControls";
import { softShadows } from '@react-three/drei'
import ReadArchive from '../../components/holotchViewer/VRViewer/Archive';
import FrameLoader from '../../components/pageloaders/FrameLoader';
// eslint-disable-next-line
import PageLoader from '../../components/pageloaders/mainpageloader';
// import recorder from 'react-canvas-recorder';
// import { toast } from "react-toastify";
// import FileSaver from 'file-saver';
// import { useCapture } from 'react-capture';
import SharingContent from '../../components/SharingContent';
// import { triggerBase64Download } from 'react-base64-downloader';
import ImageLoader from '../../components/pageloaders/ImageLoader';

// import React, { useState, useCallback, useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
// import awsCloudFront from 'aws-cloudfront-sign';


softShadows();

const CameraController = () => {
    const { camera, gl } = useThree();
    React.useEffect(
        () => {
            const controls = new OrbitControls(camera, gl.domElement);
            controls.minDistance = 3;
            controls.maxDistance = 20;
            controls.maxPolarAngle = Math.PI / 2
            return () => {
                controls.dispose();
            };
        },
        [camera, gl]
    );
    return null;
};


export const playStatus = {
    PLAYING: 0,
    PAUSED: 1,
    BUFFERING: 2,
    FINISHED: 3,
}



function HologramUi({ switchViewerMode, popMenu, popSettingsMobile, popDetailsMobile, viewerModeProps, menuProps, ...props }) {
    const { captureBtn } = viewerModeProps;
    /*
  HOLOGRAM SECTION LOGIC HANDLERS
   */
    const [status, setStatus] = React.useState(playStatus.PLAYING)
    const [timelinePos, setTimelinePos] = React.useState(0)
    const [hologramLength, setHologramLength] = React.useState(0)
    const [framerate, setFramerate] = React.useState(0)
    const [checkLoader, setCheckLoader] = React.useState(true)
    const [arView, setARView] = React.useState(false)
    const [cameraCapture, setCameraCapture] = React.useState(false)
    const [capturedPhoto, setCapturedPhoto] = React.useState('')
    const [recoredActive, setRecoredActive] = React.useState(false)
    const [getLike, setGetLike] = React.useState(false)
    const [streamReady, setStreamReady] = React.useState(false)
    const [archiveId, setArchiveId] = React.useState('')
    const [videoRecTimer, setVideoRecTimer] = React.useState('00:00')
    const [recTimerSec, setRecTimerSec] = React.useState('none')
    const [iosViewer, setIosViewer] = React.useState(false)
    const [iosExpand, setIosExpand] = React.useState(true)
    const [streamData, setStreamData] = React.useState({})
    const [startStreaming, setStartStreaming] = React.useState(false)

    const refEelement = React.useRef()
    const playerProps = {
        status,
        timelinePos,
        hologramLength,
        framerate,
        checkLoader
    };

    // eslint-disable-next-line
    const [messageHistory, setMessageHistory] = React.useState([]);
    const [getMsg, setGetMsg] = React.useState('');
    // eslint-disable-next-line
    const websocketDevUrl_dev = "ws://127.0.0.1:18000/ws/holotch/test/"
    // eslint-disable-next-line
    const websocketLiveUrl = "wss://stream.holotch.com/ws/holotch/test/"
    const {
        // eslint-disable-next-line
        sendMessage,
        lastMessage,
        readyState,
    } = useWebSocket(websocketLiveUrl);
    // eslint-disable-next-line
    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    React.useEffect(() => {
        const q_params = new URLSearchParams(props.location.search);
        setArchiveId(q_params.get('archive_id'));
        if (archiveId !== '') {
            setIosViewer(true)
        }
    }, [props.location.search, archiveId]);



    React.useEffect(() => {
        if (lastMessage !== null) {
            setMessageHistory(prev => prev.concat(lastMessage));
            const getFrameData = JSON.parse(lastMessage.data);
            if (getFrameData && getFrameData.frame) {
                console.log('\n reading Frame ', getFrameData.frame_id);
                setGetMsg(getFrameData.frame);
                setStreamData(getFrameData);
            }
        }

    }, [lastMessage, setMessageHistory]);

    const restartStream = () => {
        let socket = new WebSocket(websocketLiveUrl);
        let sendFinishedData = {
            "command": "get_next_frame",
            "sender": "readerWorker",
        };
        let signal = JSON.stringify({ ...sendFinishedData })
        if (streamReady) {
            // setGetMsg('');
            socket.onopen = function () {
                socket.send(signal);
            }
        }

    }

    const toggleArVr = () => {
        setARView(!arView);
    }
    // const iosExpandHandler = (iosExpand) => {
    //     setIosExpand(!iosExpand);
    // }
    // React.useEffect(() => {
    //     let secret = 'OVXs9eU8uLOTLitCGGukR6oDkssRoUQGtr8k3jn4';
    //     let id = 'AKIAWYOZHOOAJVO5QWA6';
    //     let url = 'https://d33cam3goooz1c.cloudfront.net';
    //     let filename = '00316ff0-bb15-11ec-be3a-ad0cf09ffd36/';
    //     var options = { keypairId: id, privateKeyPath: secret };
    //     var signedUrl = awsCloudFront.getSignedUrl(url + filename, options);
    //     console.log('signedUrl ', signedUrl);
    // }, [])

    return (
        <>

            <PageLoader archivePreLoader={true} mainLoader={false} viewerLoader={checkLoader} setStartStreaming={setStartStreaming} />

            <ViewerContainer id="mainscreen2">
                <div className="col-md-12">
                    {/* Top Screen Floating buttom components  */}
                    <SectionOne
                        popSettingsMobile={popSettingsMobile}
                        popDetailsMobile={popDetailsMobile}
                        arView={arView}
                        startStreaming={startStreaming}
                        toggleArVr={toggleArVr}
                        restartStream={restartStream}
                        videoRecTimer={videoRecTimer}
                        recTimerSec={recTimerSec}
                        iosViewer={iosViewer}
                        iosExpand={iosExpand}
                    />
                    <PlayerGesturesDesktop
                        popMenu={popMenu}
                        menuProps={menuProps}
                        popDetailsMobile={popDetailsMobile}
                        popSettingsMobile={popSettingsMobile}

                    />

                    <SharingContent
                        handleShare={handleShare}
                        getLike={getLike}
                        setGetLike={setGetLike}
                        iosViewer={iosViewer}
                    />
                    <CapturedPhotoOverLay display={cameraCapture ? 'block' : 'none'}>
                        <div className="row mx-1">
                            <div className="col-12 card-header" style={{ background: "linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212" }}>
                                <div className="row">
                                    <div className="col-6 text-start" onClick={() => setCameraCapture(false)}>
                                        <i className="bi bi-x fs-2"></i> <span className="">&ensp; Photo capture</span>
                                    </div>
                                    <div className="col-6 text-end" onClick={() => setCameraCapture(false)}>
                                        <i className="bi bi-trash fs-4"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-10 col-lg-7 mx-auto">
                                <ImageLoader
                                    cls={`img-fluid captureOverlay `}
                                    spinnerSize={15}
                                    src={capturedPhoto}
                                />
                            </div>
                            <div className="col-12 card-header pb-4" style={{ background: "linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212" }}>
                                <div className="row">
                                    <div className="col-7 text-start mx-lg-auto mb-2" onClick={() => handleShare('')}>
                                        <i className="fa fa-share"></i> <span className="">&ensp; Share</span>
                                    </div>
                                    <div className="col-7 text-start mx-lg-auto" onClick={() => savePhoto(capturedPhoto, setCameraCapture)}>
                                        <i className="bi bi-save"></i> <span className="">&ensp; Save to photos</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CapturedPhotoOverLay>
                    <MobileWrapper>
                        {sendMessage !== "me" ?
                            <>
                                <Canvas
                                    linear shadows
                                    mode="concurrent"
                                    camera={{ fov: 75 }}
                                    id='hologramsection'
                                    ref={refEelement}
                                    gl={{
                                        preserveDrawingBuffer: true,
                                    }}
                                >
                                    <CameraController />
                                    <directionalLight castShadow position={[0, 10, 0]} color='#FFC08A' intensity={0.3}
                                        shadow-mapSize-width={1024}
                                        shadow-mapSize-height={1024}
                                        shadow-camera-far={50}
                                        shadow-camera-left={-10}
                                        shadow-camera-right={10}
                                        shadow-camera-top={10}
                                        shadow-camera-bottom={-10}
                                    />
                                    <BaseEnvironment />
                                    <ReadArchive
                                        pageLoaderCheck={pageLoaderCheck}
                                        setCheckLoader={setCheckLoader}
                                        setMetadata={setMetadata}
                                        setHologramLength={setHologramLength}
                                        setFramerate={setFramerate}
                                        incrementTimelinePos={incrementTimelinePos}
                                        setTimelinePos={setTimelinePos}
                                        restartPlayback={restartPlayback}
                                        updateStatus={updateStatus}
                                        setStatus={setStatus}
                                        forward2Secs={forward2Secs}
                                        changeTimeLinePos={changeTimeLinePos}
                                        playerState={playerProps}
                                        timelinePos={timelinePos}
                                        hologramLength={hologramLength}
                                        framerate={framerate}
                                        status={status}
                                        scale={0.8}
                                        setStreamReady={setStreamReady}
                                        getMsg={getMsg}
                                        archiveId={archiveId}
                                        streamData={streamData}

                                    />
                                </Canvas>
                                <FrameLoader loaded={checkLoader} setIndex={true} />
                            </>
                            : ""}


                    </MobileWrapper>



                    <div className="d-lg-none d-block">
                        <PlayerGestures
                            popMenu={popMenu}
                            menuProps={menuProps}
                            popDetailsMobile={popDetailsMobile}
                            popSettingsMobile={popSettingsMobile}
                        />
                    </div>
                    {/* Video and Photo switch components  */}
                    <SectionTwo
                        switchViewerMode={switchViewerMode}
                        viewerModeProps={viewerModeProps}
                        recoredActive={recoredActive}
                        iosViewer={iosViewer}


                    />

                    {/* AR/VR Switch component  */}
                    <SectionThree
                        captureBtn={captureBtn}
                        refEelement={refEelement}
                        recoredActive={recoredActive}
                        arView={arView}
                        viewerModeProps={viewerModeProps}
                        toggleArVr={toggleArVr}
                        capturePhoto={capturePhoto}
                        startRecording={startRecording}
                        setRecoredActive={setRecoredActive}
                        setCapturedPhoto={setCapturedPhoto}
                        setCameraCapture={setCameraCapture}
                        secondsToMinutes={secondsToMinutes}
                        setVideoRecTimer={setVideoRecTimer}
                        setRecTimerSec={setRecTimerSec}
                        iosViewer={iosViewer}
                        handleShare={handleShare}
                        archiveId={archiveId}
                        iosExpand={iosExpand}
                    />
                    {/* Time line component*/}
                    <PlayerTimeLine
                        maxVal={hologramLength}
                        currentVal={timelinePos}
                        changeTimeLinePos={changeTimeLinePos}
                        setTimelinePos={setTimelinePos}
                        totalTime={new Date((hologramLength && framerate > 0 ? hologramLength / framerate : 0) * 1000).toISOString().substr(11, 8)}
                        currentTime={new Date((timelinePos ? timelinePos / framerate : 0) * 1000).toISOString().substr(11, 8)}
                        playStatus={playStatus}
                        togglePlayPause={togglePlayPause}
                        status={status}
                        setStatus={setStatus}
                        restartPlayback={restartPlayback}
                        iosViewer={iosViewer}
                        iosExpand={iosExpand}
                        setIosExpand={setIosExpand}
                        iosExpandHandler={iosExpandHandler}
                    />
                </div>
                <ViewerDesktopSideBar cls='col-lg-3 d-none'>
                    <DesktopDetailSection />
                </ViewerDesktopSideBar>
            </ViewerContainer >

        </>
    )
}

const mapStateToProps = (state) => ({
    viewerModeProps: state.hologramUIReducer,
    menuProps: state.popMenuReducer,
});

export default connect(mapStateToProps, {
    switchViewerMode,
    popMenu,
    popSettingsMobile,
    popDetailsMobile
})(HologramUi);

// export default HologramUi;
