import React from 'react';
import {
    // MDBContainer, MDBRow,
    MDBCol
} from 'mdb-react-ui-kit';
import { FrameLoaderOverlay } from './pageloader.style';
import { FadeLoader } from "react-spinners";

function FrameLoader({ loaded }) {
    const [isIOS, setIsIOS] = React.useState(false)
    React.useEffect(() => {
        const userAgent = navigator.userAgent;
        if (/iPad|iPhone|iPod/i.test(userAgent)) {
            setIsIOS(true)
        }
    }, [setIsIOS])

    return (
        <>
            <MDBCol center className={`text-white text-center mx-auto ${isIOS ? 'col-1' : ''}`}>
                <FrameLoaderOverlay
                    display={loaded ? 'block' : 'none'}
                    setIndex={loaded === 'block' ? true : false}
                >
                    <div className="mx-auto text-center pb-3">
                        <FadeLoader color="#FFFF" width={4} height={15} radius={2} margin={2} />

                    </div>
                    <br />
                    <div className="d-block d-md-none col-6 text-center" style={{ position: 'relative', left: `${isIOS ? '-50px' : '30%'}`, top: '20px' }}>
                        <p className="mt-lg-5 mt-2">
                            <span className="small text-center">Loading Hologram...</span>
                        </p>
                    </div>
                    <div>
                        <div className="text-center text-white d-none d-md-block">
                            <p className="mt-lg-5 mt-2 text-center ms-5">
                                <span className="small">Loading Hologram...</span>
                            </p>
                        </div>
                    </div>
                </FrameLoaderOverlay>
            </MDBCol>
        </>
    )
}

export default FrameLoader
