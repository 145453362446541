import React from 'react';
import { playStatus } from '../hologram/hologramUi.helpers';
import * as THREE from 'three';
import * as B64 from 'base64-arraybuffer';
var currIdx = 0
var started = false
var prevKeyFrame = 0
// eslint-disable-next-line
var playingFrames = false
// eslint-disable-next-line
var resetBuffer = false
var frameBuffer = []
var mainPlaybackBuffer = []

function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}


function getGeometry(encodedGeom) {
    if (encodedGeom !== null) {
        var decoder = new TextDecoder("utf-8");
        var view = new DataView(encodedGeom, 0, encodedGeom.byteLength);
        var string = decoder.decode(view);
        var geometry = JSON.parse(string);
        return geometry
    } else {
        return null
    }

}

function getSortedFrames(batch) {
    const ordered = Object.keys(batch).sort().reduce(
        (obj, key) => {
            obj[key] = batch[key];
            return obj;
        },
        {}
    );
    var frameArray = []
    Object.keys(ordered).forEach(key => {
        frameArray.push(ordered[key])
    });
    return frameArray
}

function BuildMesh(idx, ref) {

    return (
        <mesh ref={ref} key={idx} castShadow={true} receiveShadow={false} scale={0.007} position={[0, -10, 0]}>
            <bufferGeometry
                attach="geometry"
                onUpdate={self => self.computeVertexNormals()}
            >
            </bufferGeometry>

            <meshBasicMaterial attach='material' side={THREE.DoubleSide} />

        </mesh>)
}


class ReadArchive extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            meshNum: 1,
            batchSize: 30,
            framePos: 0,
            frameCount: 0,
            dracoEncoding: true,
            startAtMostRecent: false,
            requesting: false, //whether we're currently requesting from S3
            loaded: false, //whether the first frame has been loaded
            readerWorker: null, //web worker for parsing mesh data
            onGoingReaderRequests: new Set(),//keep track of ongoing reader worker jobs
            nextFrame: null, //next frame in the frameBuffer          
            currMeshList: [], //meshes to be rendered
            meshRefs: [], //references to the meshes in currMeshList
            frameQueue: {}, //accumulates frames from the batch currently being processed
            latestBatch: 0, //batch being currently fetched
            lengthInBatches: 0, //recording length in batches
            SQSparams: {},
            batchWokerReady: false, //whether the batch worker is ready
            getLiveFrame: '', //get the live frame from the server
        };
        this.receivedReaderWorkerMessage = this.receivedReaderWorkerMessage.bind(this)
        this.updateBufferGeometry = this.updateBufferGeometry.bind(this)
        this.sendBatchToWorker = this.sendBatchToWorker.bind(this)
        this.startBufferTimer = this.startBufferTimer.bind(this)
        this.playFrames = this.playFrames.bind(this)
    }


    //update each mesh geometry buffer using references
    updateBufferGeometry() {
        var frameToRender = this.state.nextFrame
        for (let i = 0; i < this.state.meshNum; i++) {
            if (frameToRender[i] !== undefined && frameToRender[i].textureData !== undefined) {
                //get mesh and its reference
                var ref = this.state.meshRefs[i]
                var mesh = frameToRender[i]
                //get decoder output texture data 
                var texData = new Uint8Array(frameToRender[i].textureData.buf)
                var height = frameToRender[i].textureData.height
                var width = frameToRender[i].textureData.width
                var vertices, triangles, uvs
                if (this.state.dracoEncoding) {
                    if (mesh.geometry != null) {
                        vertices = new Float32Array(Object.values(mesh.geometry.attributes[0].array))
                        uvs = new Float32Array(Object.values(mesh.geometry.attributes[1].array))
                        triangles = new Uint16Array(Object.values(mesh.geometry.index.array))
                    } else {
                        continue
                    }
                } else {
                    vertices = mesh.geometry.vertices
                    uvs = new Float32Array(Object.values(mesh.geometry.uvs))
                    triangles = mesh.geometry.faces
                }
                const texture = new THREE.DataTexture(texData, width, height, THREE.RGBAFormat);
                //create new geometry buffer with the data extracted previously
                var newGeometry = new THREE.BufferGeometry()
                newGeometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
                newGeometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
                newGeometry.setIndex(new THREE.BufferAttribute(triangles, 1));
                newGeometry.computeVertexNormals()
                var newMat = new THREE.MeshBasicMaterial()
                newMat.side = THREE.FrontSide
                newMat.map = texture
                //access mesh through reference, replace geometry and texture
                // const geometry = new THREE.PlaneGeometry(1, 1);
                ref.current.geometry = newGeometry
                ref.current.material = newMat
            }
        }
    };

    async playFrames() {
        while (true) {
            if (mainPlaybackBuffer.length > 0) {
                if (this.props.playerState.status !== playStatus.PAUSED) {
                    //if we were previously buffering, update the state to playing
                    if (this.props.playerState.status === playStatus.BUFFERING) this.props.updateStatus(playStatus.PLAYING)
                    this.setState({
                        nextFrame: mainPlaybackBuffer.shift()
                    }, this.updateBufferGeometry)
                }
            }
            await delay(57)
        }
    }


    startBufferTimer() {

        setInterval(() => {
            console.log(mainPlaybackBuffer.length)
            if (mainPlaybackBuffer.length > 2) {
                mainPlaybackBuffer = frameBuffer
            } else {
                mainPlaybackBuffer = mainPlaybackBuffer.concat(frameBuffer)
            }
            frameBuffer = [];
        }, 198)
    }


    receivedReaderWorkerMessage(event) {
        if (event.data.type === 'decoderReady') {
            this.props.setStreamReady(true)
            console.log('Ready')
            //whether the batch worker is ready
            this.setState({ batchWokerReady: true })
        } else
            if (event.data.type === 'Processed Frame') {
                //console.log(event.data)
                var batchStart = event.data.batchStart
                //use batch only if it's still valid
                var batch = this.state.frameQueue
                var geometry
                if (this.state.dracoEncoding) {
                    geometry = getGeometry(event.data.geometry);
                } else {
                    geometry = event.data.geometry
                }
                let textureData = { buf: event.data.textureData, height: event.data.textureHeight, width: event.data.textureWidth }
                let mesh = { geometry: geometry, textureData: textureData };
                if (!batch.hasOwnProperty(event.data.frameBatchPos)) batch[event.data.frameBatchPos] = {};
                (batch[event.data.frameBatchPos])[event.data.meshID] = mesh;
                this.setState({ frameQueue: batch })
                if (event.data.decodingJobsLeft === 0) {
                    var frameArray = getSortedFrames(batch)
                    //only start geometry updater at the beginning
                    if (!this.state.loaded) {
                        var refs = []
                        var baseMeshList = []
                        var meshNum = this.state.meshNum
                        //create build meshes and create references to them
                        for (let i = 0; i < meshNum; i++) {
                            var ref = React.createRef()
                            refs.push(ref)
                            var baseMesh = BuildMesh(i, ref)
                            baseMeshList.push(baseMesh)
                        }
                        frameBuffer = frameArray
                        this.setState({
                            frameQueue: {},
                            loaded: true,
                            meshRefs: refs,
                            currMeshList: baseMeshList
                        })
                        this.startBufferTimer();
                        this.playFrames()
                    } else {
                        frameBuffer = frameBuffer.concat(frameArray)
                        this.setState({
                            frameQueue: {}
                        })
                    }
                    this.state.onGoingReaderRequests.delete(batchStart)
                }

            }
    }

    sendBatchToWorker(batchData) {
        if (this.props.playerState.status !== playStatus.PAUSED && this.state.batchWokerReady) {
            // eslint-disable-next-line
            //var decodedData = B64.decode(batchData)
            //console.log('\n base64 size: ', batchData.length);
            //console.log('\n decoded data size: ', decodedData.length);
            var currKeyFrame = batchData.key_frame_id
            if (!started && currKeyFrame !== 0) {
                //discard frames until a new keyframe
                //console.log("Skipping non-keyframe...")
            } else {
                if (!started) {
                    started = true;
                }
                //console.log('prev: ',prevKeyFrame,' curr: ',currKeyFrame,' keyframeID: ',batchData.key_frame_id)
                if (currKeyFrame === prevKeyFrame + 1 || currKeyFrame === 0) {
                    var message = {
                        type: 'Batch',
                        batch: B64.decode(batchData.frame),
                        timelinePos: this.props.playerState.timelinePos,
                        pos: currIdx,
                        keyframeID: batchData.key_frame_id
                    }
                    currIdx = currIdx + 1

                    this.state.readerWorker.postMessage(message)
                    prevKeyFrame = currKeyFrame
                } else {
                    //discard frames until a new keyframe
                    console.log("Frame lost. Skipping non-keyframe...")
                }
            }
        }
    }

    //Toogle MeshNum
    toggleMeshNum = () => {
        const q_params = new URLSearchParams(window.location.search);
        let getMsn = 1
        if (parseInt(q_params.get('msn'))) {
            getMsn = parseInt(q_params.get('msn'))
            // this.setState({ meshNum: getMsn })
            // eslint-disable-next-line
            this.state.meshNum = getMsn;
            console.log('meshNum= ', this.state.meshNum, getMsn)
        }
    }

    componentDidMount() {
        // meshNum
        this.toggleMeshNum()
        var readerWorker = new Worker(process.env.PUBLIC_URL + "/ReaderWorker.js", { type: "module" })
        readerWorker.onmessage = this.receivedReaderWorkerMessage
        var workerList = []
        var worker = new Worker(process.env.PUBLIC_URL + "/Decoder.js")

        workerList.push(worker)
        this.setState({
            readerWorker: readerWorker,
            decoderWorkers: workerList
        })
        var message = {
            type: 'Setup',
            dracoEncoding: this.state.dracoEncoding,
            url: process.env.PUBLIC_URL,
            workerNum: this.state.meshNum
        }
        readerWorker.postMessage(message)
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.timelinePos !== this.props.timelinePos) {
            if (this.state.loaded) {
                this.props.pageLoaderCheck(this.state.loaded, this.props.setCheckLoader)
            }
        }

        if (prevProps.getMesh !== this.props.getMesh) {
            this.setState({
                positioner: this.props.getMesh
            })
        }
        if (prevProps.msn !== this.props.msn) {
            this.toggleMeshNum()
        }
        // live frames
        if (prevProps.getMsg !== this.props.getMsg) {
            //console.log(this.props.streamData)
            localStorage.setItem('msg', this.props.getMsg)
            //let stream = localStorage.getItem('msg')
            this.sendBatchToWorker(this.props.streamData)
        }

    }

    //return null;

    render() {
        if (this.state.loaded) {
            return this.state.currMeshList
        } else {
            return null
        }
    }
}


export default ReadArchive;